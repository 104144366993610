/* eslint-disable */
import { getArrFromStrUniq } from '@/utils'

const handleSearchFormProps = {
  orderCodeList: strList,
  orderCodeLikeList: strList,
  
  expressWaybillCodeList: strList,
  expressWaybillCodeLikeList: strList,
}
export default handleSearchFormProps


function strList(val) {
  console.log(val)
  const value = getArrFromStrUniq(val)
  console.log(value)
  if (value.length) return value
}
function createStrList(prop) {
  return (val) => {
    const value = strList(val)
    if (value) {
      return {
        $isMergeData: true,
        [prop]: value
      }
    }
  }
}